import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import bgImage from '../images/run.jpg';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const IndexPage = () => (
  <Layout backgroundImage={bgImage}>
    <SEO
      title="Physio"
      description="P3 Physio, peak performance physiotherapy"
      keywords={[`P3 Physio`, `application`, `react`]}
    />

    <section>
      <h1>Running Analysis and Technique Coaching</h1>
      <p>
        Fed up with being injured but love running. Interested in becoming more
        efficient with your running technique.{' '}
      </p>
      <p>
        Video analysis of running style covers foot strike, cadence, stride
        length, upper and lower limb position and stability. Following the
        initial assessment, technique drills, strengthening/ flexibility and
        movement drills will help transform your running technique.
      </p>
      <p>
        <a href="/contact">Get in touch today</a> to find out more.
      </p>
    </section>
  </Layout>
);

export default IndexPage;
